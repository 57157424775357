<!-- BEGIN PAGE HEADER-->
<div class="row">
    <div class="col-md-12">
        <!-- BEGIN PAGE TITLE & BREADCRUMB-->
        <h3 class="page-title">
            {{'SIDEBAR.PaymentCompleted' | translate }}
        </h3>
        <ul class="page-breadcrumb breadcrumb">

            <li style="margin-left: 0.5rem; margin-right: 0.5rem">
                <a [routerLink]="['/main/dashboard']">{{'APPLICATIONSPAGE.Home'|translate}}</a>
                <i class="fa fa-angle-right" style="padding: 0 1rem"></i>
            </li>
            <li>
                {{'SIDEBAR.PaymentCompleted' | translate }}
                <i class="fa fa-angle-right" style="padding: 0 1rem"></i>
            </li>
            <li>{{'PAYMENT.View'|translate}}</li>

        </ul>
        <!-- END PAGE TITLE & BREADCRUMB-->
    </div>
</div>
<!-- END PAGE HEADER-->
<!-- BEGIN PAGE CONTENT-->

<div class="row">
    <div class="col-md-12">
        <div class="portlet box blue">

            <div class="portlet-title">
                <div class="caption">{{'PAYMENT.View' |translate}}</div>
                <div class="tools">
                    <!-- <a href="javascript:;" class="collapse"></a> -->
                </div>
            </div>

            <div class="portlet-body form">
                <form class="form-horizontal" role="form">
                    <div class="form-body">


                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="col-md-3 control-label">{{'PAYMENT.File Number'|translate}} :</label>
                                    <div class="col-md-6">
                                        <p class="form-control-static">{{paymentDetails?.fileNumber}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="col-md-3 control-label">{{'PAYMENT.Paid Amount'|translate}} :
                                    </label>
                                    <div class="col-md-6">
                                        <p class="form-control-static">{{paymentDetails?.amountPaid}}&nbsp;{{paymentDetails?.paymentCurrency}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="col-md-3 control-label">{{'PAYMENT.Instruction Type'|translate}}
                                        :</label>
                                    <div class="col-md-6">
                                        <p class="form-control-static" *ngIf="paymentDetails?.instrType==='EVISA_PAY'"> {{'SIDEBAR.Manage Applications'|translate}}</p>
                                        <p class="form-control-static" *ngIf="paymentDetails?.instrType==='EVISA_EXT'">{{'EXTENSIONVISA.Apply Visa Extension'|translate}}</p>
                                        <!--<p class="form-control-static"> {{paymentDetails?.instrType}}</p>-->
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="col-md-3 control-label">{{'PAYMENT.PaymentMethod'|translate}}
                                        :</label>
                                    <div class="col-md-6">
                                        <p class="form-control-static"> {{paymentDetails?.paymentMethod}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>




                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="col-md-3 control-label">{{'PAYMENT.Status'|translate}}
                                        :</label>
                                    <div class="col-md-6">
                                        <p class="form-control-static"> {{paymentDetails?.status}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="col-md-3 control-label">{{'PAYMENT.Invoiced Date'|translate}}
                                        :</label>
                                    <div class="col-md-6">
                                        <p class="form-control-static"> {{paymentDetails?.createdDate}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="col-md-3 control-label">{{'PAYMENT.PaidDate'|translate}}
                                        :</label>
                                    <div class="col-md-6">
                                        <p class="form-control-static"> {{paymentDetails?.updatedDate}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>




                        <div class="form-actions fluid">
                            <div class="text-center">
                                <button [routerLink]="['/main/payment-completed']" routerLinkActive="active"
                                    type="button" class="btn default">{{'PAYMENT.Cancel'|translate}}</button>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
        </div>

    </div>
</div>