<!-- BEGIN HEADER -->
<div class='header navbar navbar-inverse navbar-fixed-top'>
	<!-- BEGIN TOP NAVIGATION BAR -->
	<div class='header-inner'>
		<!-- BEGIN LOGO -->
		<!-- <span class='newrta'> <img src='assets/img/headerlogo.png' alt='' width='200' height='40' /></span> -->
		<!-- END LOGO -->
		<!-- BEGIN RESPONSIVE MENU TOGGLER -->
		<a href='javascript:;' class='navbar-toggle' data-toggle='collapse' data-target='.navbar-collapse'>
			<img src='assets/img/menu-toggler.png' alt='' />
		</a>
		<!-- END RESPONSIVE MENU TOGGLER -->
		<!-- BEGIN TOP NAVIGATION MENU -->
		<ul class='nav navbar-nav pull-right'>
			<!-- BEGIN USER LOGIN DROPDOWN -->
			<li class='dropdown user'>
				<a href='#' class='dropdown-toggle' data-toggle='dropdown' data-hover='dropdown'
					data-close-others='true'>
					<!-- <img alt='' [src]="'assets/img/profile/header-profile.png'"/>&nbsp; -->
					<!-- {{this.getToken('profilePic') == null}} -->
					<img alt='' style="width: 30px;"
						[src]="(this.getToken('profilePic')?this.base64Image+this.getToken('profilePic'):'assets/img/profile/header-profile.png')" />&nbsp;

					<span class='username'>{{this.getToken('username')}}</span>
					<i class='fa fa-angle-down'></i>
				</a>
				<ul class='dropdown-menu'>
					<li><a href='javacsript:;' [routerLink]="['/main/my-profile']"><i class='fa fa-user'></i>
							{{'HEADER.myProfile'|translate}}</a></li>
					<li class='divider'></li>
				
					<li><a href='javascript:;' (click)="logout()"><i class='fa fa-key'></i>
							{{'HEADER.Logout'|translate}}</a></li>
				</ul>
			</li>
			<!-- END USER LOGIN DROPDOWN -->
		</ul>
		<!-- END TOP NAVIGATION MENU -->
	</div>
	<!-- END TOP NAVIGATION BAR -->
</div>
<!-- END HEADER -->