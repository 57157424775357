import { Component, OnInit, Injector,HostListener } from '@angular/core';
import { BaseComponent } from '../../common/commonComponent';
import { loadStripe } from '@stripe/stripe-js';
import { environment } from 'src/environments/environment';

declare var $: any;
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent extends BaseComponent implements OnInit {

 
  constructor(inj: Injector) {
    super(inj);

  }
 
  stripePromise = loadStripe(environment.stripe_key);
  async checkout(e) {
  
    // Call your backend to create the Checkout session.
    // When the customer clicks on the button, redirect them to Checkout.
    const stripe = await this.stripePromise;
    let tmpamt: any = this.getToken('charge').split(" ")[0]
    const payment = {
      amount: tmpamt * 100,
      referenceNumber: this.getToken('fileNumber'),
      /* cancelUrl: 'http://localhost:4200/cancel',
      successUrl: 'http://localhost:4200/#/main/success-payment', */
      /* cancelUrl: 'http://217.160.99.180/applicant-api/cancel',
      successUrl: 'http://217.160.99.180/applicant-api/#/main/success-payment', */
      cancelUrl: 'https://evisav2.gouv.dj/applicant-api/#/main/dashboard',
      successUrl: 'https://evisav2.gouv.dj/applicant-api/#/main/success-payment',
      instrType: 'EVISA_PAY'
    };

    // this is a normal http calls for a backend api
    this.http
      .post(`${environment.serverUrl}`, payment)
      .subscribe((data: any) => {
        // I use stripe to redirect To Checkout page of Stripe platform
        // stripe.redirectToCheckout({
        //   sessionId: data.refId,
        // }); 
        const result = stripe.redirectToCheckout({
          sessionId: data.refId,
        });
        console.log(result);
      });
   

  }


  ngOnInit(): void {
    //this.invokeStripe();
   
  }


  invokeStripe() {
    if (!window.document.getElementById('checkout-button')) {
      const script = window.document.createElement('script');
      script.id = 'checkout-button';
      script.type = 'text/javascript';
      script.src = 'https://js.stripe.com/v3/';
      window.document.body.appendChild(script);
    }
  }

  public tokenData: any;
  makePayment(amount: any) {
    // const paymentHandler = (<any>window).StripeCheckout.configure({
    //   key:
    //     'pk_test_51IS2RDErV2ejINDYYLbdrNrP7MtaU3XYSlDl2U5FbZKCnMe3mmjF0jeRIJmW8WDM9my7o5gaXavu3eYelp3VFjzi00nd696K5Q',

    //   locale: 'auto',
    //   token: function (stripeToken: any) {
    //     console.log(stripeToken.card);
    //     console.log(stripeToken)
    //     this.tokenData = stripeToken.card
    //     const url = 'http://localhost:8081/applicant-api/v1/api/payment/charge';
    //      let amt:any=sessionStorage.getItem("charge").split(" ")[0]
    //      let senddata = {

    //         "amount":amt*100,
    //         "currency": "USD",
    //         "referenceNumber":sessionStorage.getItem("fileNumber"),
    //         "recieptMail":stripeToken.card.name,
    //         "stripeEmail":stripeToken.card.name,
    //         "stripeToken":stripeToken.id

    //     }

    //     var request = new Request(url,{
    //       method: 'POST',
    //       body: JSON.stringify(senddata),
    //       headers: new Headers({'content-type': 'application/json'}),
    //     });

    //     fetch(request)
    //       .then(function (res) {
    //         // Handle response we get from the AP
    //         console.log(res);
    //       })
    //   },
    // });

    // paymentHandler.open({
    //   name: 'Evisa Payment',
    //   description: '',
    //   amount: amount * 100,
    // });
    // console.log(this.tokenData)
  }







}
