import { Component, Injector, OnInit } from '@angular/core';
import { BaseComponent } from '../../../common/commonComponent';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { loadStripe } from '@stripe/stripe-js';
import { environment } from 'src/environments/environment';
import { NgPopupsService } from 'ng-popups';
declare var $: any
@Component({
  selector: 'app-pending-payment-list',
  templateUrl: './pending-payment-list.component.html',
  styleUrls: ['./pending-payment-list.component.css']
})


export class PendingPaymentListComponent extends BaseComponent implements OnInit {

  public pagesize = 10;
  public totalItem: any;
  public data: any = {
    pageNumber: 1,
    pageSize: this.pagesize,
  }

  constructor(inj: Injector, private ngxLoader: NgxUiLoaderService, private ngPopups: NgPopupsService) {
    super(inj);
  }

  message: any;
  moneyRefund: any;

  ngOnInit(): void {
    this.getPendingPayment(this.data);
    this.getDateToday();

    if (localStorage.getItem('Language') === 'en') {
      this.message = "Are you sure, want to pay ?"
      this.moneyRefund = "Note: Once the payment is made in the application, there is no refund"


    } else {
      this.message = "Êtes-vous sûr de vouloir payer?"
      this.moneyRefund = "Attention: Le frais de visa n'est pas garantie d'un visa accordé. Il est non-remboursable en cas de refus de la demande ou annulation de la demande par le demandeur"
    }
  }
  refreshPage() {
    this.getPendingPayment(this.data);
  }

  /****************************************************************************
      @PURPOSE      : To retrive the pending paymentFAQ List
      @PARAMETERS   : pageNumber,PageSize, loggeduser, status
      @RETURN       : pendingPaymentDTO
   ****************************************************************************/
  public number: 0;
  public size: 0;
  public noofelements: 0;
  public totalElements: 0;
  public loading: boolean = false;
  public datetoday: any;
  pendingPayment: any = []


  getPendingPayment(data) {
    this.loading = true;
    this.ngxLoader.start();
    setTimeout(() => {
      this.data.loggedUser = this.getToken('username'),
        this.data.status = 'PP';
     // this.data.instrType = "EVISA_PAY"
      this.commonService.callApi('payment/search', data, 'post', false, false, 'REG').then(success => {
        let successData: any = success;
        this.totalItem = successData.totalElements;
        this.number = successData.number;
        this.size = successData.size;
        this.loading = false;
        this.ngxLoader.stop();
        this.noofelements = successData.numberOfElements;
        this.totalElements = successData.totalElements;
   console.log(successData);
          if (successData.content.length) {
          this.pendingPayment = successData.content
          console.log(this.pendingPayment);

        } else {
          this.pendingPayment = [];
        }
      }).catch(e => {
        this.ngxLoader.stop();
        this.toastr.error(e.message, 'Oops!');
      });
    });
  }

  getDateToday() {
        this.loading = true;
        this.ngxLoader.start();
        setTimeout(() => {
            // this.data.instrType = "EVISA_PAY"
            this.commonService.callApi('dateressource', '', 'post', false, false, 'REG').then(success => {
                let successData: any = success;
                let currentDate = new Date(successData);

                // Ajouter une semaine à la date actuelle
                currentDate.setDate(currentDate.getDate() + 7);

                // Mettre à jour successData avec la nouvelle date
                successData = currentDate.toISOString();

                this.datetoday = successData;
                this.ngxLoader.stop();
            }).catch(e => {
                this.ngxLoader.stop();
                this.toastr.error(e.message, 'Oops!');
            });
        });
    }




  /******************************************************************************/

  /****************************************************************************
      @PURPOSE      : Pagination
      @PARAMETERS   : pageNumber,PageSize,loggedinuse
      @RETURN       : NA
   ****************************************************************************/
  public currentPage = 1;
  public showBoundaryLinks = true;
  public rangeList = [5, 10, 25, 100];
  public isFilterApplied: boolean = false;
  public filterObj: any = {};
  pageChanged(e) {

    if (this.isFilterApplied) {
      this.filterObj.pageNumber = e.page;
      this.filterObj.pageSize = e.itemsPerPage;
      this.getPendingPayment(this.filterObj);
    } else {
      this.data.pageNumber = e.page;
      this.data.pageSize = e.itemsPerPage;
      this.getPendingPayment(this.data);
    }
  }
  rangeChanged(e) {
    if (this.isFilterApplied) {
      this.filterObj.pageSize = e;
      this.getPendingPayment(this.filterObj);
    } else {
      this.data.pageSize = e;
      this.getPendingPayment(this.data);
    }
  }
  /*******************************************************************************/
  stripePromise = loadStripe(environment.stripe_key);
  async checkout(file, amount, instrType) {
    console.log(amount)

    // Call your backend to create the Checkout session.
    // When the customer clicks on the button, redirect them to Checkout.
    const stripe = await this.stripePromise;
    this.ngPopups.confirm(this.moneyRefund, { title: this.message }).subscribe(res => {
      if (res) {

        const payment = {
          amount: amount * 100,
          referenceNumber: file,
          /* cancelUrl: 'http://localhost:4200/cancel',
          successUrl: 'http://localhost:4200/#/main/success-payment', */
          /* cancelUrl: 'http://217.160.99.180/applicant-api/cancel',
          successUrl: 'http://217.160.99.180/applicant-api/#/main/success-payment', */
          cancelUrl: 'https://evisav2.gouv.dj/applicant-api/#/main/dashboard',
          successUrl: 'https://evisav2.gouv.dj/applicant-api/#/main/success-payment',
          instrType: instrType
        };

        // this is a normal http calls for a backend api
        this.http
          .post(`${environment.serverUrl}`, payment)
          .subscribe((data: any) => {
            // I use stripe to redirect To Checkout page of Stripe platform
            // stripe.redirectToCheckout({
            //   sessionId: data.refId,
            // }); 
            const result = stripe.redirectToCheckout({
              sessionId: data.refId,
            });
            console.log(result);
          });

      }
    });
  }


}
