export const faqData={
  data: [
    {   "id":1,
        "Question":"1. What is the eVisa ?",
        "Answer":"The eVisa is a document that certifies that the holder is authorized to enter and stay in the Republic of Djibouti for a specified period. It replaces the visa issued at the point of entry into the country, which is usually an airport or border crossing. Applicants can obtain their visa electronically, after providing the required information and making the payment online. Once the application is approved, the eVisa is sent to the applicant by e-mail. Candidates are required to print their eVisa and keep it with their passport at all times when travelling in the Republic of Djibouti. The eVisa is only valid if the purpose of the trip is tourism, business or transit. Other purposes, such as work and study, require a traditional visa obtained at embassies or consulates. The eVisa, like the tradditional visa, does not guarantee entry into the Republic of Djibouti. The border authorities may refuse entry on rare occasions.",
        "QuestionFrench":"Qu'est-ce l'eVisa?",
        "AnswerFrench":"L'eVisa est un document qui certifie que le titulaire est autorisé à entrer et séjourner en République de Djibouti pour une période déterminée. Il remplace le visa délivré au point d'entrée dans le pays, qui est habituellement un aéroport ou un poste frontalier. Les demandeurs peuvent obtenir leur visa par voie électronique, après avoir fourni les informations requises et effectué le paiement en ligne. Une fois que la demande est approuvée, l'eVisa est envoyé au demandeur par courrier électronique. Les candidats sont tenus d'imprimer leur eVisa et de le conserver avec leur passeport en tout temps, lorsqu'ils voyagent en République de Djibouti. L'eVisa n'est valide que si le but du voyage est le tourisme, le commerce ou le transit. D'autres fins, tels que le travail et les études, exigent un visa traditionnel obtenu aux ambassades ou consulats. L'eVisa, à l'instar du visa traditionnel, ne garantit pas l'entrée en République de Djibouti. Les autorités frontalières peuvent refuser l'entrée en de rares occasions."
    },
    {
        "id":2,
        "Question":"2. What do I need to apply for an electronic visa ?",
        "Answer":"A passport valid for at least 6 months, a flight reservation (or any other possible means of transport), an accommodation address (Hotel or at your host's), an invitation letter (organisation or host with their contacts) and a credit card (visa, Mastercard or American Express).",
        "QuestionFrench":"Que me faut-il pour faire une demande de visa électronique ?",
        "AnswerFrench":"Un passeport valide pour 6 mois minimum, une réservation de vol (ou tout autre moyen possible de transport), une adresse d’hébergement (Hôtel ou chez votre hôte), une lettre d'invitation (organisation ou hôte avec leurs contacts) et une carte de crédit (visa, Mastercard ou American Express)."
    },
    {
        "Question":"3.What are the advantages of the eVisa ?",
        "Answer":"You can easily get your eVisa from any location connected to the Internet. You will therefore not have to go through the traditional visa application process. You will also make your arrival process easier.",
        "QuestionFrench":"Quels sont les avantages de l'eVisa ?",
        "AnswerFrench":"Vous pouvez facilement obtenir votre eVisa depuis n'importe quel endroit connecté à Internet. Vous n'aurez donc pas à passer par le processus traditionnel de demande de visa. Vous vous faciliterez également votre processus d'arrivée."
    },
    {
        "Question":"4. Who can request an eVisa ?",
        "Answer":"Any person wishing to travel to the Republic of Djibouti is eligible for the eVisa as long as the reason for travel is family, tourist or business. You can also request an e-Visa if you are in transit. If you are a holder of a diplomatic, service or official passport or if you are part of a program eligible for a courtesy visa and you travel within the official framework, you may be exempt from visa requirements. Please refer to the program that suits you. For any other reason, you must visit the nearest Embassy or Consulate General of the Republic of Djibouti to apply for a visa.",
        "QuestionFrench":"Qui peut demander un eVisa ?",
        "AnswerFrench":"Toute personne voulant se rendre en République de Djibouti est éligible pour l'eVisa. Si vous être détendeur d’un passeport diplomatique, de service ou officiel, ou si vous faites partie d’un programme éligible au visa de courtoisie vous êtes exempté de visa. Si votre motif de séjour est autre que le tourisme ou le commerce, vous devez visiter l'Ambassade / le Consulat général de la République de Djibouti le plus proche pour faire une demande de visa."
    },
    {
        "Question":"5. Do I have to enter the Republic of Djibouti on the exact date specified in my application ?",
        "Answer":"The validity period of your eVisa begins on the date you specified in your application. You may enter the Republic of Djibouti on any date within this validity period.",
        "QuestionFrench":"Dois-je entrer en République de Djibouti à la date exacte spécifiée dans ma demande ?",
        "AnswerFrench":"La période de validité de votre eVisa commence à la date que vous avez spécifié dans votre demande. Vous pouvez entrer en République de Djibouti à n'importe quelle date à l'intérieur de cette période de validité."
    },
    {
        "Question":"6. If my travel date changes, can I change the date on my eVisa ?",
        "Answer":"No. If you do not use your eVisa during its validity period, you will need to request another one.",
        "QuestionFrench":"Si ma date de voyage change, puis-je modifier la date sur mon eVisa ?",
        "AnswerFrench":"Non. Si vous n'utilisez pas votre eVisa pendant sa période de validité, vous devrez en demander un autre."
    },
    {
        "Question":"7. Is it safe ?",
        "Answer":"The Republic of Djibouti shall not sell, rent or otherwise use for commercial purposes to third parties the personal information entered on the eVisa website. Data obtained in each step of the process eVisa are stored in high security conditions. eVisa issued electronically and printed by the are entirely the responsibility of the applicant. The responsibility also rests with the applicant if this information is somehow in the hands of third parties. Information concerning the applicant stored in the barcode on the eVisa, are encrypted by the Ministry of the Interior of the Republic of Djibouti and signed electronically. Access to the content of this information is given to Only to institutions authorized by the Ministry of the Interior of the Republic of Djibouti. Problems caused by an alteration of the barcode are the responsibility of the applicant.",
        "QuestionFrench":"Est-ce sûr?",
        "AnswerFrench":"La République de Djibouti ne vend pas, ne loue pas à des tiers ni n'utilise autrement à des fins commerciales les informations personnelles saisies sur le site Internet du eVisa. Les données obtenues en chaque étape du processus eVisa sont stockés dans des conditions de haute sécurité. Les eVisa émis par voie électronique et imprimés par le demandeur sont entièrement sous sa responsabilité. La responsabilité appartient également au demandeur si cette information se retrouve d'une manière quelconque entre les mains de tierces personnes. Les informations concernant le demandeur stockés dans le code-barres se trouvant sur l'eVisa, sont chiffrés par le Ministère de l’Intérieur de la République de République de Djibouti et signés électroniquement. L'accès au contenu de ces informations est donné seulement aux institutions autorisées par le Ministère de l’Intérieur de la République de République de Djibouti. Les problèmes causés par d'une altération du code-barres sont sous la responsabilité du demandeur."
    },
    {
        "Question":"8. Do I need a separate eVisa for my companions ?",
        "Answer":"Yes, each traveller must have their own eVisa.",
        "QuestionFrench":"Dois-je obtenir un eVisa séparé pour les personnes qui m'accompagnent?",
        "AnswerFrench":"Oui, chaque voyageur doit avoir son propre eVisa."
    },
    {
        "Question":"9. Is the eVisa refundable ?",
        "Answer":"No. Your eVisa will not be refunded even if you do not use it.",
        "QuestionFrench":"L'eVisa est-il remboursable?",
        "AnswerFrench":"Non. Votre eVisa ne vous sera pas remboursé même si vous ne l'utilisez pas."
    },
    
    {
        "Question":"10. Can I get a multiple-entry eVisa?",
        "Answer":"No, the eVisa is only valid for one entry.",
        "QuestionFrench":"Puis-je entrer en République de Djibouti si une partie des informations sur mon eVisa ne correspond pas aux informations sur mon passeport?",
        "AnswerFrench":"Non, vous ne le pouvez pas. L'eVisa est associé électroniquement avec le passeport d'un passager. Par conséquent, vous devez entrer en République de Djibouti avec le même passeport avec lequel vous avez demandé un eVisa. Sinon, votre eVisa sera invalide."
    },
    {
        "Question":"11. Can I enter the Republic of Djibouti if some of the information on my eVisa does not match the information on my passport ?",
        "Answer":"No, you can't do that. The eVisa is associated electronically with a passenger's passport. Therefore, you must enter the Republic of Djibouti with the same passport with which you applied for an eVisa. Otherwise, your eVisa will be invalid.",
        "QuestionFrench":"Je tiens à rester en République de Djibouti pour une durée plus longue que ne le permet l'eVisa. Que dois-je faire?",
        "AnswerFrench":"Si vous souhaitez rester en République de Djibouti plus que ne le permet votre eVisa, vous devez vous adresser aux services de Police locaux pour un permis de séjour. Veuillez noter que l'eVisa est délivré uniquement à des fins de tourisme et de commerce. La demande d'autres types de visa (visa de travail, visa d'étudiant etc.) doit être fait par le biais des ambassades et consulats djiboutiens. Si vous dépassez la durée de votre visa, vous pouvez être invité à payer des amendes, être déporté ou interdit de voyager en République de Djibouti à l'avenir pour une certain temps."
    },
    {
        "Question":"12. I want to stay in the Republic of Djibouti for a longer period than the eVisa allows. What am I supposed to do ?",
        "Answer":"If you wish to stay in the Republic of Djibouti more than your eVisa allows, you must apply to the local police services for a permit to stay. Please note that the eVisa is issued for tourism and commercial purposes only. The application for other types of visa (work visa, visa student etc.) must be done through Djiboutian embassies and consulates. If you exceed the duration of your visa, you may be required to pay a fine and face deportation or a travel ban to the Republic of Djibouti for a period of time.",
        "QuestionFrench":"Est-il sûr de faire un paiement par carte de crédit sur la page internet du eVisa ?",
        "AnswerFrench":"Le site Web applique des normes de sécurité de niveau élevé. En revanche, nous ne pouvons être tenus pour responsables de toute défaillance de sécurité due à l'ordinateur que vous utilisez ou à votre connexion Internet."
    },
    {
        "Question":"13. Is it safe to make a credit card payment on the eVisa website ?",
        "Answer":"The website has high security standards. However, we cannot be held responsible for any security failure due to the computer you use or your Internet connection.",
        "QuestionFrench":"Je me suis rendu compte qu'une partie des informations que j'ai fournies au cours de ma demande d'eVisa ne correspondent pas à ceux figurant sur mon passeport. Que dois-je faire?",
        "AnswerFrench":"Vous devez faire une nouvelle demande."
    },
    {
        "Question":"14. I have found that some of the information I provided during my eVisa application does not match the information on my passport. What am I supposed to do ?",
        "Answer":"You must apply again.",
        "QuestionFrench":"J'ai complété ma demande. Combien de temps cela prendra-t-il pour que mon eVisa me soit envoyé par courrier électronique?",
        "AnswerFrench":"Votre eVisa vous sera envoyé par courriel habituellement dans les 72 heures ouvrées suivant la date à laquelle vous avez rempli votre demande et que les éléments de décision sont réunis. En République de Djibouti, les jours de weekend sont le Vendredi et le Samedi."
    },
    {
        "Question":"15. I've completed my application. How long will it take for my eVisa to be emailed to me ?",
        "Answer":"Your eVisa will usually be emailed to you within 72 hours of the date you completed your application and the decision elements are in place. In the Republic of Djibouti, weekend days are Friday and Saturday.",
        "QuestionFrench":"Que dois-je faire si ma demande de visa électronique n'est pas approuvée?",
        "AnswerFrench":"Si vous avez reçu un message électronique de notification pour vous informer que votre demande de visa électronique n'a pas été approuvée, vous pouvez entrer en contact avec l'ambassade ou le consulat de République de Djibouti le plus proche pour une demande de visa traditionnel."
    
    },
    {
        "Question":"16. What do I do if my electronic visa application is not approved ?",
        "Answer":"If you have received an electronic notification message informing you that your electronic visa application has not been approved, you may contact the nearest embassy or consulate of the Republic of Djibouti for a traditional visa application.",
        "QuestionFrench":"Si ma demande d'eVisa est refusée, serai-je remboursé?",
        "AnswerFrench":"Les frais de visa sont des frais administratifs. Ils ne seront donc pas remboursés si votre eVisa n’est pas accordé."
    },
    {
        "Question":"17. If my eVisa application is refused, will I be reimbursed?",
        "Answer":"Visa fees are an administrative fee. They will not be refunded if your eVisa is not granted.",
        "QuestionFrench":"Combien de jours avant la date de mon voyage dois-je demander mon eVisa ?",
        "AnswerFrench":"Vous pouvez demander un eVisa au plus tard 72 heures avant votre voyage. Toutefois, il est conseillé de faire la demande au moins une semaine avant votre voyage."
    },
    {
      "Question":"18. How many days before my travel date should I request my eVisa",
      "Answer":"You can request an eVisa at least 72 hours before your trip. However, it is advisable to apply at least one week before your trip and take into account the weekend days in the Republic of Djibouti (Friday and Saturday).",
      "QuestionFrench":"J'ai fait une demande de visa auprès d'une ambassade (ou consulat général) de République de Djibouti. Je veux faire le point sur ma demande. Puis-je me renseigner à travers ce site web?",
      "AnswerFrench":"Non, vous devez contacter l'ambassade (consulat général) en question pour obtenir des informations sur votre demande."

    },
    {
        "Question":"19. I applied for a visa at an embassy (or consulate general) of the Republic of Djibouti. I want to get an update on my request. Can I get information through this website ?",
        "Answer":"No, you must contact the embassy (consulate general) in question to obtain information about your application.",
        "QuestionFrench":"Je me suis aperçu que certains des renseignements que j'avais fournis lors de ma demande d'eVisa ne correspondent pas aux informations sur mon passeport. J'ai effectué le paiement, et maintenant j'ai un eVisa avec des informations erronées. Puis-je obtenir un remboursement?",
        "AnswerFrench":"Non. Le demandeur est responsable de toute erreur."
    },
    {
        "Question":"20. I have found that some of the information I provided when I applied for an eVisa does not match the information on my passport. I made the payment, and now I have an eVisa with incorrect information. Can I get a refund ?",
        "Answer":"No. The applicant is responsible for any errors.",
        "QuestionFrench":"Je ne veux pas faire une demande d’eVisa. Puis-je toujours obtenir un visa à l'aéroport?",
        "AnswerFrench":"Non, aucun visa ne sera délivré à l’aéroport ou tout autre point frontière à compter du 1er février 2018."
    },
    {
        "Question":"21. No. The applicant is responsible for any errors.",
        "Answer":"No, no visas will be issued at the airport or any other border point as of May 1, 2018.",
        "QuestionFrench":"Je n'ai pas de carte de crédit ou de carte bancaire. Y-a-t-il un autre moyen de payer les frais de mon eVisa ?",
        "AnswerFrench":"Non, vous ne pouvez effectuer le paiement qu'avec une carte de crédit ou une carte bancaire (uniquement Mastercard, Visa ou American Express). Par contre, la carte ne doit pas obligatoirement être à votre nom."
    },
    {
        "Question":"22. I don't have a credit or debit card. Is there another way to pay for my eVisa ?",
        "Answer":"No, you can only pay with a credit card or bank card (Mastercard, Visa or American Express only). However, the card does not have to be in your name.",
        "QuestionFrench":"Je ne peux pas effectuer le paiement. Que dois-je faire?",
        "AnswerFrench":"Veuillez vous assurer que la carte est soit une «MasterCard», soit une «Visa» (elle ne doit pas être obligatoirement à votre nom), soit une «American Express» ou «Amex», qu’elle possède le système « 3D Secure » et qu’elle est ouverte aux transactions internationales. Si votre carte a toutes ces propriétés et que vous ne pouvez toujours pas effectuer le paiement, vous pouvez essayer de payer à un autre moment ou avec une autre carte. Si le problème persiste, vous pouvez contacter votre banque pour demander son assistance. Au cas où vous ne réussissez toujours pas, s'il vous plaît « Contactez-nous» (Page d'accueil)."
    },
    {
        "Question":"23. I can't make the payment. What am I supposed to do ?",
        "Answer":"Please make sure that the card is either a 'MasterCard', 'Visa' or 'American Express' ('Amex') (it does not have to be in your name), that it has the 3D Secure 'system and that it is open to international transactions. If your card has all these properties and you still cannot make the payment, you can try to pay at another time or with another card. If the problem persists, you can contact your bank for assistance. In case you still don't succeed, please 'Contact Us' (Home Page).",
        "QuestionFrench":"Dans la section 'Informations personnelles' il n'y a pas de place pour le deuxième prénom. Où dois-je écrire mon deuxième prénom?",
        "AnswerFrench":"Saisissez votre deuxième prénom dans la case 'Prénom' après votre prénom et un espace blanc."

    },
    {
        "Question":"24. In the 'Personal Information' section there is no space for the middle name. Where do I write my middle name ?",
        "Answer":"Enter your middle name in the 'First name' box after your first name and a blank space.",
        "QuestionFrench":"Je veux que l'adresse sur ma facture soit différente de l'adresse sur mon eVisa. Est-ce possible?",
        "AnswerFrench":"Non. L'adresse sur votre facture est automatiquement prélevée sur votre eVisa."
    },
    {
        "Question":"25. I want the address on my invoice to be different from the address on my eVisa. Is that possible ?",
        "Answer":"Yes, the address on your invoice is the one you provide at the time of payment. It may therefore differ from the address on your eVisa.",
        "QuestionFrench":"Que veut dire numéro CVV / CVC / CVC2 ?",
        "AnswerFrench":"Pour Visa et MasterCard CVV / CVC / CVC2 sont les trois chiffres du numéro imprimé sur la bande de signature au dos de votre carte (Visa, Mastercard) et les quatre numéro à l'avant de votre Amex."
    },
    {
        "Question":"26. What does CVV / CVC / CVC2 number mean?",
        "Answer":"For Visa and MasterCard CVV / CVC / CVC2 are the three digits of the number printed on the signature strip on the back of your card (Visa, Mastercard) or the four numbers on the front of your Amerian Express.",
        "QuestionFrench":"Mon enfant est inscrit dans mon passeport. Dois-je faire une demande de eVisa séparée pour lui /pour elle?",
        "AnswerFrench":"Oui. Veuillez utiliser les données d'identification de votre enfant et les informations de votre passeport pour cette demande."
    },
  
    {
        "Question":"27. How much does an eVisa cost?",
        "Answer":"eVisa fees vary by visa type; A transit visa (from 1 to 14 days) costs $12 and a short stay visa (from 15 to 90 days) will cost $23.",
        "QuestionFrench":"Combien coûte un eVisa?",
        "AnswerFrench":"Les frais du eVisa varient selon le type de visa ; Un visa de transit (1 à 14 jours) coûte 12$ et un visa de court séjour (15 à 90 jours) vous coutera 23$."
    },
    {
        "Question":"28. How long will my eVisa be valid for ?",
        "Answer":"The validity period of your eVisa will start on the date you enter the Republic of Djibouti. Please note that the duration of validity is different from the duration of the stay. The length of your stay cannot exceed that mentioned on your eVisa.",
        "QuestionFrench":"Quelle sera la durée de validité de mon eVisa?",
        "AnswerFrench":"La durée de validité de votre eVisa débutera à la date de votre entrée en République de Djibouti. Veuillez noter que la durée de validité est différente de la durée du séjour. La durée du séjour ne peut excéder la durée de séjour mentionnée sur votre eVisa."
    },
    {
        "Question":"29. Do I need a transit visa even if I do not leave the airport ?",
        "Answer":"No, you do not need a transit visa if you do not leave the transit area.",
        "QuestionFrench":"Dois-je obtenir un visa de transit même si je ne quitte pas l'aéroport?",
        "AnswerFrench":"Non, vous n'avez pas besoin de visa de transit si vous ne quittez paz la zone de transit."
    }
]}