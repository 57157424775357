<br>

<div class="login-content">

	<!-- <label > Language:</label>
	<select #langSelect (change)="translate.use(langSelect.value);translateLang(langSelect.value)">
		<option *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang === translate.currentLang">{{ lang }}</option>
		
	</select> -->

	<!-- BEGIN LOGO -->
	<!-- <div class="logo">
		<a href="landing.html"><img src="assets/img/final.png" width="250px" alt="" /> </a>
	</div> -->
    <h3><a href="javascript:;" ><img style="width:65px"
        src="assets/img/warning.png" alt="" /></a></h3>
    <table class="table_style" style="margin-top: -10px;">
        <tbody>
            <tr>
                <td class="table_td_blank_style"></td>
            </tr>
            <tr>
                <td class="table_td_app_style">
                    <b class="text_color">
                        <h3><strong style="color: #f4f4f4;
                            background: #cc3532;">APPLICATION SECURITY ERROR !!!</strong></h3>
                    </b>
                    <br>
                    <b class="text_color1 guilty">
                        This error has occured for one of the following reasons:
                        <br><br>
                    </b>
                    <ul class="sessionTime_simpletext unknownUX5J">

                        <li class="guilty">You have logged in from another browser window.<br>
                        </li>
                        <li class="guilty">You have kept the browser window idle for a long time.<br>
                        </li>
                        <li class="guilty">You are accessing the application URL from a saved or static page.<br>
                        </li>
                        <li class="guilty">The access to the application is disabled. Contact Administrator.<br>
                        </li>
                    </ul>
                </td>
                <td class="table_td_style">
                    <div class="unknownAppErrorIcon"><span class="icon-warning2"></span></div>
                </td>

            </tr>
            <tr>
                <td class="table_td_blank_style"></td>
            </tr>
        </tbody>
    </table>
	<!-- END LOGO -->

	



</div>




<ngx-ui-loader hasProgressBar=false fgsType="three-strings" ></ngx-ui-loader>