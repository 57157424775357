
            
    <div class="modal fade" id="portlet-config" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                    <h4 class="modal-title">Modal title</h4>
                </div>
                <div class="modal-body">
                    Widget settings form goes here
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn blue">Save changes</button>
                    <button type="button" class="btn default" data-dismiss="modal">Close</button>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
    <!-- /.modal -->
    <!-- END SAMPLE PORTLET CONFIGURATION MODAL FORM-->
    <!-- BEGIN STYLE CUSTOMIZER -->
    
    <!-- END BEGIN STYLE CUSTOMIZER -->            
    <!-- BEGIN PAGE HEADER-->
    <div class="row">
        <div class="col-md-12">
            <!-- BEGIN PAGE TITLE & BREADCRUMB-->
            <h3 class="page-title">
                eVisa Application 
            </h3>
            <ul class="page-breadcrumb breadcrumb">
                <li>
                    <i class="fa fa-home"></i>
                    <a href="applicationsListAPP.html">Home</a> 
                    <i class="fa fa-angle-right"></i>
                </li>
                <li>eVisa<i class="fa fa-angle-right"></i></li>
                <li><a href="#">Apply</a></li>
            </ul>
            <!-- END PAGE TITLE & BREADCRUMB-->
        </div>
    </div>
    <!-- END PAGE HEADER-->
    <!-- BEGIN PAGE CONTENT-->
    <div class="row">
        <div class="col-md-12">
        
            <div class="portlet box blue">
                <div class="portlet-title">
                    <div class="caption">Notification</div>
                    <div class="tools">

                    </div>
                </div>
                <div class="portlet-body">

                    <div class="alert alert-block alert-info fade in">
                        <button type="button" class="close" data-dismiss="alert"></button>
                        <p>
                           <b>{{'PAYMENT.fileref'|translate}}</b>{{this.getToken('appNo')}}<br><br>
                            <b>{{'PAYMENT.visaCharge'|translate}}</b> {{this.getToken('charge')}}<br><br>
                            {{'PAYMENT.information2'|translate}} <br><br>
                            {{'PAYMENT.information3'|translate}}<br><br>
                            {{'PAYMENT.information4'|translate}} <br>
                        </p>
                        <p>
                            <a class="btn green" href="javascript:;" (click)="checkout($event)">{{'PAYMENT.makepayement'|translate}}</a>
                             <a class="btn btn-link" href="javacript:;" [routerLink]="['/main/dashboard']">{{'PAYMENT.later'|translate}}</a>
                             <!-- <button id="checkout-button" type="button" (click)="pay()">Checkout</button> -->
                             <!-- <button (click)="checkout()">Checkout</button> -->
                        </p>
                    </div>
                </div>
            </div>
                        
        
        </div>

    </div>

        

    