// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   stripe: 'pk_test_51IS2RDErV2ejINDYYLbdrNrP7MtaU3XYSlDl2U5FbZKCnMe3mmjF0jeRIJmW8WDM9my7o5gaXavu3eYelp3VFjzi00nd696K5Q',
//   serverUrl: 'http://localhost:8081/applicant-api/v1/api/payment/charge',
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

export const environment = {
  production: true,
  /* stripe_key: 'pk_test_51IS2RDErV2ejINDYYLbdrNrP7MtaU3XYSlDl2U5FbZKCnMe3mmjF0jeRIJmW8WDM9my7o5gaXavu3eYelp3VFjzi00nd696K5Q', */
  stripe_key: 'pk_live_51PKbmaCk3phQxdKylkJZlUl13H6rTr9aEIWmmF1frQn2lcKBzp3fsar0JCZMs7UWZ58wWN6NExtumqAhzgSObG6H00gTCtdXVI',
  // serverUrl: 'http://localhost:8081/applicant-api/v1/api/payment/checkout'
  serverUrl: window.location.href.split('#')[0]+'v1/api/payment/checkout'
   // urlpayementPenality: 'http://localhost:8081/applicant-api/v1/api/applyoverstay'
};
