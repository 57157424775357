<div class="row">
    <div class="col-md-12">
        <!-- BEGIN PAGE TITLE & BREADCRUMB-->
        <h3 class="page-title">
            {{'SIDEBAR.PaymentCompleted' | translate }}
            <!-- <small> Applications' | translate }}</small> -->
        </h3>
        <ul class="page-breadcrumb breadcrumb">
            <li style="margin-left: 0.5rem; margin-right: 0.5rem">
                <a href="javascript:;" [routerLink]="['/main/dashboard']">{{'APPLICATIONSPAGE.Home' | translate }}</a>
                <i class="fa fa-angle-right" style="padding: 0 1rem"></i>
            </li>
            <li>{{'SIDEBAR.PaymentCompleted' | translate }}</li>

        </ul>
        <!-- END PAGE TITLE & BREADCRUMB-->
    </div>
</div>
<!-- END PAGE HEADER-->

<div class="row">
    <div class="col-md-12">
        <!-- BEGIN EXAMPLE TABLE PORTLET-->
        <div class="portlet box blue">
            <div class="portlet-title">
                <div class="caption">{{'SIDEBAR.PaymentComplete' | translate }}</div>
                <div class="tools">
                    <!-- <a href="javascript:;" class="collapse" tooltip="Collapse"></a> -->
                    <a href="javascript:;" style="position: relative;
                    top: 0px;
                    color: #e5e5e5;" (click)="refreshPage()" tooltip="Refresh"><i class="fa fa-refresh"></i></a>
                     <a style="color: white;" class="nav-link pull-right" tooltip="Filter" href="javascript:;"
                     data-toggle="collapse" href="#collapseExample" aria-expanded="false"
                     aria-controls="collapseExample">
                     <i class="fa fa-filter" aria-hidden="true"></i>
                 </a>
                </div>
            </div>
            <div class="portlet-body">
                <div class="collapse" id="collapseExample">

                    <div class="portlet-body form">
                        <div id="filterBlock" class="filter-block animated fadeInDown">
                            <form #filterForm="ngForm" (ngSubmit)="applyFilter()">
                                <div class="row">
                                    <div class="col-md-12">
                                       
                                        <div class="col-md-3">
                                            <label>{{'DASHBOARDPAGE.File Number' | translate }}</label>

                                            <input class='form-control text' type="text" name="fileNumber"
                                                [(ngModel)]="filterObj.fileNumber"
                                                placeholder="{{'DASHBOARDPAGE.File Number' | translate }}"
                                                id="fileNumber" required #fileNumber="ngModel" autocomplete="off" />
                                        </div>


                                    </div>
                                </div>

                                <div class="form-actions fluid">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="col-md-offset-3 col-md-9">
                                                <button type="submit"
                                                    class="btn blue pull-right">{{'DASHBOARDPAGE.ApplyFilter'|translate}}</button>

                                                <button type="button" *ngIf="isFilterApplied" class="btn  pull-right"
                                                    (click)="resetFilter()">{{'DASHBOARDPAGE.ResetFilter'|translate}}</button>
                                                &nbsp;
                                            </div>
                                        </div>
                                        <div class="col-md-6"></div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="table-scrollable">
                    <table  class="styled-table"
                        id="sample_editable_1">
                        <thead>
                            <tr>
                                <th>{{'PAYMENT.File Number'|translate}}</th>
                                <th>{{'PAYMENT.Paid Amount'|translate}}</th>
                                <th>{{'PAYMENT.Instruction Type'|translate}}</th>
                                <th>{{'PAYMENT.PaymentMethod'|translate}}</th>

                                <th>{{'PAYMENT.Status'|translate}}</th>
                                <th>{{'PAYMENT.Invoiced Date'|translate}}</th>
                                <th>{{'PAYMENT.PaidDate'|translate}}</th>
                                <th>{{'PAYMENT.Action'|translate}}</th>
                                <!-- <th>Payment Invoice</th> -->
                            </tr>
                        </thead>
                        <tbody>


                            <tr class="odd gradeX" *ngFor="let data of paymentCompleted">
                                <td>{{data?.fileNumber}}</td>
                                <td>{{data?.amountPaid}} {{data?.paymentCurrency}}</td>
                                <!--<td>{{data?.instrType}}</td>-->
                                <td *ngIf="data?.instrType==='EVISA_PAY'">{{'SIDEBAR.Manage Applications'|translate}}</td>
                                <td *ngIf="data?.instrType==='EVISA_EXT'">{{'EXTENSIONVISA.Apply Visa Extension'|translate}}</td>
                                <td *ngIf="data?.instrType==='EVISA_PEN'">{{'EXTENSIONVISA.VisaPenality'|translate}}</td>
                                <td>{{data?.paymentMethod}}</td>

                                <td *ngIf="data?.status==='PC'">Payment Completed</td>
                                <td>{{data?.createdDate | date:'dd-MM-yyyy HH:mm:ss'}}</td>
                                <td>{{data?.updatedDate| date:'dd-MM-yyyy HH:mm:ss'}}</td>
                                <td>
                                    &nbsp;
                                    <a href="javascript:;" tooltip="View"
                                        [routerLink]="['/main/payment-completed/payment-edit/',data?.fileNumber]"
                                        routerLinkActive="active">View</a>
                                    &nbsp;&nbsp;
                                <!-- </td> -->
                                <!-- <td> -->
                                    <a href="javascript:;" tooltip="Download"
                                        (click)="onDownload(data?.paymentInstructions,data?.fileNumber)">{{'PAYMENT.invoice' | translate }}</a>
                                </td>
                            </tr>
                            <tr class="odd" *ngIf="(!paymentCompleted.length) &&(!loading)">
                                <td style="text-align: center" colspan="10" class="dataTables_empty"><b>{{'RECORDS.NoFound' | translate }}</b></td>
                            </tr>
                        </tbody>
                    </table>

                </div>
                <div class="row" *ngIf="totalElements>0">

                    <div class="col-md-4 col-sm-12">
                        <div class="btn-group">
                            <ng-select (change)="rangeChanged(pagesize)" [items]="rangeList" bindLabel="name"
                                bindValue="id" placeholder="Select" [(ngModel)]="pagesize"
                                [ngModelOptions]="{standalone: true}" [clearable]='false' [searchable]='false'
                                [dropdownPosition]="'top'">
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-12">
                        <div class="dataTables_info" id="sample_editable_1_info">{{'PAGINATION.Showing'|translate}}
                            {{(number*size) + 1}} {{'PAGINATION.to'|translate}}
                            {{(number*size) + noofelements}} <!-- {{'PAGINATION.of'|translate}} {{totalElements}} -->
                            {{'PAGINATION.entries'|translate}}
                        </div>

                    </div>
                    <div class="col-md-4 col-sm-12">
                        <div class="dataTables_paginate paging_bootstrap">
                            <pagination class="page" [boundaryLinks]="showBoundaryLinks" [rotate]="'true'"
                                [itemsPerPage]='pagesize' name="currentPage" ngDefaultControl [totalItems]="totalItem"
                                [maxSize]="5" [(ngModel)]="currentPage" [ngModelOptions]="{standalone: true}"
                                (pageChanged)="pageChanged($event)" previousText="&lsaquo;" nextText="&rsaquo;"
                                firstText="&laquo;" lastText="&raquo;">
                            </pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END EXAMPLE TABLE PORTLET-->
    </div>
</div>

<ngx-ui-loader hasProgressBar=false fgsType="three-strings"></ngx-ui-loader>