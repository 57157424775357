import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manage-applications',
  template : `<router-outlet></router-outlet>`
})
export class ManageApplicationsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
