import {Component, Injector, OnInit} from '@angular/core';
import { NgPopupsService } from 'ng-popups';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { loadStripe } from '@stripe/stripe-js';
import { environment } from 'src/environments/environment';
import {ActivatedRoute} from "@angular/router";
import {BaseComponent} from "../common/commonComponent";
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';



@Component({
  selector: 'app-visaoverstay-view',
  templateUrl: './visaoverstay-view.component.html',
  styleUrls: ['./visaoverstay-view.component.css']
})
export class VisaoverstayViewComponent extends BaseComponent implements OnInit {
    depConfig: Partial<BsDatepickerConfig>;
    colorTheme = 'theme-blue';
    public data: any = {
        applicationNumber: 1,
        loggedUser: this.getToken('username'),
        departedestimate: '',
        visa_expiry:'',
        penalityAmount:''
    }

    isValid: boolean = false;
    isissueValid: boolean = false;
  diplayPenalitedEstimate: number;

  applicationNumber : string;
    penalityamount: any;
    public overstayDto: any;
    error : any;
  constructor(inj: Injector, private ngxLoader: NgxUiLoaderService, private ngPopups: NgPopupsService, private activedRoute:ActivatedRoute) {
      super(inj);
      this.new_date = moment(new Date(), "DD-MM-YYYY").add(1, 'days').toDate();
      this.applicationNumber= this.activedRoute.snapshot.params['applicationNumber'];
      this.penalityamount=this.activatedRoute.snapshot.params['amount'];
      this.depConfig = Object.assign({}, { containerClass: this.colorTheme, customTodayClass: 'custom-today-class', showWeekNumbers: false, dateInputFormat: 'DD-MM-YYYY', minDate: this.new_date});
  }
    message: any;
    moneyRefund: any;
  ngOnInit(): void {
      this.getVisaExtension();

      this.diplayPenalitedEstimate=0;
      if (localStorage.getItem('Language') === 'en') {
          this.message = "Are you sure, want to pay ?"
          this.moneyRefund = "Note: Once the payment is made in the application, there is no refund"


      } else {
          this.message = "Êtes-vous sûr de vouloir payer?"
          this.moneyRefund = "Attention: Le frais de visa n'est pas garantie d'un visa accordé. Il est non-remboursable en cas de refus de la demande ou annulation de la demande par le demandeur"
      }
  }

    public today = new Date();
    new_date: any;
    datePickerConfig = {
        dateInputFormat: 'DD-MM-YYYY',
        containerClass: this.colorTheme,
        maxDate: new Date(),
        showWeekNumbers: false
    }

    getVisaExtension() {
 this.data.applicationNumber=this.applicationNumber;
        setTimeout(() => {
            this.commonService.callApi('searchavailableoverstayview', this.data, 'post', false, false, 'REG').then(success => {
                let successData: any = success;
                  successData.penalityAmount=this.penalityamount;
                this.overstayDto=successData;
                  console.log(this.overstayDto);
            }).catch(e => {
                this.toastr.error(e.message, 'Oops!');
            });
        });
    }

    getEstimatePenality() {
        this.data.penalityAmount=this.penalityamount;
        if(this.data.departedestimate > 0){
            this.error='';
              if(this.data.departedestimate > this.today){
                  this.error='';
                  setTimeout(() => {
                      this.commonService.callApi('estimatePenality', this.data, 'post', false, false, 'REG').then(success => {
                          let successData: any = success;
                          this.diplayPenalitedEstimate=successData.penalityAmount;
                          console.log(this.diplayPenalitedEstimate);
                      }).catch(e => {
                          this.toastr.error(e.message, 'Oops!');
                      });
                  });
              }else{
                  this.error='Vous ne pouvez pas sélectionner une date antérieure ou egale à la date du jour.';
              }
        }else{
            this.error='veuillez saisir une date';

        }

    }

    stripePromise = loadStripe(environment.stripe_key);
    async checkout(file, amount) {
        console.log(file);
        console.log(amount);
        this.data.applicationNumber = file;
        this.data.penalityAmount = amount;
        const stripe = await this.stripePromise;
        this.ngPopups.confirm(this.moneyRefund, {title: this.message}).subscribe(res => {
            if (res) {

                const payment = {
                    amount: this.data.penalityAmount,
                    referenceNumber: this.data.applicationNumber,
                    username:'Hamda',
                    /* cancelUrl: 'http://localhost:4200/cancel',
                    successUrl: 'http://localhost:4200/#/main/success-payment', */
                    /* cancelUrl: 'http://217.160.99.180/applicant-api/cancel',
                    successUrl: 'http://217.160.99.180/applicant-api/#/main/success-payment', */
                    cancelUrl: 'https://evisav2.gouv.dj/applicant-api/cancel',
                    successUrl: 'https://evisav2.gouv.dj/applicant-api/#/main/success-payment',
                    instrType: 'EVISA_PEN'
                };

                // this is a normal http calls for a backend api
                console.log('environment.serverUrl',environment.serverUrl);
                this.http
                    .post(`${environment.serverUrl}`, payment)
                    .subscribe((data: any) => {
                        // I use stripe to redirect To Checkout page of Stripe platform
                        // stripe.redirectToCheckout({
                        //   sessionId: data.refId,
                        // });
                        const result = stripe.redirectToCheckout({
                            sessionId: data.refId,
                        });
                        console.log(result);
                    });
            }
        });

        console.log("dehors");
    }

    getCancel(){
         this.diplayPenalitedEstimate =0;
         this.data.departedestimate='';
         }
}
