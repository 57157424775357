<div class="page-header-fixed" style="background-color: #18191a">
   
    <!-- <app-header></app-header> -->
    <div class="clearfix"></div>
    <div class="">
       <app-sidebar ></app-sidebar>
    </div>
    <div class="page-content">
      <router-outlet></router-outlet>
    </div><!-- /.main-content -->
    <div class="footer" >
       <app-footer></app-footer>
    </div>
</div>