import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-apply-visa-extension',
  templateUrl: './apply-visa-extension.component.html',
  styleUrls: ['./apply-visa-extension.component.css']
})
export class ApplyVisaExtensionComponent  implements OnInit {

  constructor() {}
 
  ngOnInit(): void {
  } 
}
