
<div class="container ">
      <div class="centered">
      <div class="check"><i class="fa fa-check" aria-hidden="true"></i></div>
      <div class="content">
         <h3>Payment Success !</h3>
         <div class="modal-body">
            <p class="text-center">{{'PAYMENT.information-sucess1'|translate}} <br /> {{'PAYMENT.facturation'|translate}} </p>
         </div>
         <div class="modal-footer">
            <button class="btn btn-success btn-block" data-dismiss="modal" [routerLink]="['/main/dashboard']">{{'PAYMENT.gotohome'|translate}}</button>
         </div>
      </div>
   </div>
</div>