import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pending-payment',
  templateUrl: './pending-payment.component.html',
  styleUrls: ['./pending-payment.component.css']
})
export class PendingPaymentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
