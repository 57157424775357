<br>
<div class="content">
    <div class="logo center-block">
        <a href="landing.html"> <img src="assets/img/evisa.png" alt="" style="width: 300px" /> </a>
    </div>

    <h3 style="text-align: center;"> {{'PASSWORD.Update Password'|translate}} </h3>

    <form action="#" class="form-horizontal" #passwordDetails="ngForm"
        (ngSubmit)="updatePassword(passwordDetails,passwordData)">
        <div>
            <span style="color: mediumseagreen;font-size: 13px">
                {{'PASSWORD.Your password'|translate}} <b>8-20</b>
                {{'PASSWORD.characters'|translate}} <b>(!, @, $, *)</b>, {{'PASSWORD.and must not
                contain'|translate}} </span>
        </div>


        <br>



        <div class="form-group">
            <label class="col-md-5 control-label">{{'PASSWORD.CurrentPassword'|translate}}<span
                    class="required">*</span></label>
            <div class="col-md-7">
                <input class='form-control' placeholder="{{'PASSWORD.PLACEHOLDER.currentPassword'|translate}}"
                    [type]="isValue?'text':'password'" name="secretKey" [(ngModel)]="passwordData.secretKey"
                    autocomplete="off" id="secretKey" required #secretKey="ngModel">
                <span style="position:absolute; right:28px;top:8px; cursor: pointer; " (click)="onClick('secret')">
                    <i class="fa fa-eye" *ngIf="!isValue"></i>
                    <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="isValue"></i>
                </span>
                <div class="error-block " *ngIf="((submitted || secretKey.touched ) && secretKey.invalid)">
                    {{'PASSWORD.ERRORMESSAGE.Required'|translate}}
                </div>


            </div>
        </div>

        <div class="form-group">
            <label class="col-md-5 control-label">{{'PASSWORD.New Password'|translate}}<span
                    class="required">*</span></label>
            <div class="col-md-7">
                <input class='form-control' placeholder="{{'PASSWORD.PLACEHOLDER.newPassword'|translate}}"
                    [type]="show?'text':'password'" name="newsecretkey" [(ngModel)]="passwordData.newsecretkey"
                    autocomplete="off" id="newsecretkey" required #newsecretkey="ngModel"
                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[@!$*]).{8,20}" maxlength="20" appBlockCopyPaste>
                <span style="position:absolute; right:28px;top:8px; cursor: pointer; " (click)="onClick('newsecret')">
                    <i class="fa fa-eye" *ngIf="!show"></i>
                    <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="show"></i>
                </span>
                <div class="error-block" *ngIf="((submitted || newsecretkey.touched) && newsecretkey.invalid )">
                    {{'PASSWORD.ERRORMESSAGE.Required'|translate}}
                </div>
                <div class="error-block"
                    *ngIf="((submitted || newsecretkey.touched || newsecretkey.dirty) && newsecretkey.invalid )">
                    {{'PASSWORD.ERRORMESSAGE.PasswordValidation'|translate}}
                </div>
                <div class="error-block"
                    *ngIf="((submitted || newsecretkey.touched || newsecretkey.dirty) && newsecretkey.valid &&  (passwordData.secretKey == passwordData.newsecretkey))">
                    {{'PASSWORD.ERRORMESSAGE.newPassword'| translate}}
                </div>
            </div>
        </div>


        <div class="form-group">
            <label class="col-md-5 control-label">{{'PASSWORD.Confirm New Password'|translate}}<span
                    class="required">*</span></label>
            <div class="col-md-7">
                <input class='form-control' placeholder="{{'PASSWORD.PLACEHOLDER.confirmPassword'|translate}}"
                    [type]="isShow?'text':'password'" name="condirmNewsecretkey"
                    [(ngModel)]="passwordData.confirmNewsecretkey" autocomplete="off" id="confirmNewsecretkey"
                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[@!$*]).{8,20}" maxlength="20" required
                    #confirmNewsecretkey="ngModel" appBlockCopyPaste>
                <span style="position:absolute; right:28px;top:8px; cursor: pointer; " (click)="onClick('cnfrmnew')">
                    <i class="fa fa-eye" *ngIf="!isShow"></i>
                    <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="isShow"></i>
                </span>
                <div class="error-block"
                    *ngIf="((submitted || confirmNewsecretkey.touched) && confirmNewsecretkey.invalid)">
                    {{'PASSWORD.ERRORMESSAGE.Required'|translate}}
                </div>
                <div class="error-block"
                    *ngIf="((submitted || confirmNewsecretkey.touched || confirmNewsecretkey.dirty) && confirmNewsecretkey.invalid )">
                    {{'PASSWORD.ERRORMESSAGE.PasswordValidation'|translate}}
                </div>

                <div class="error-block"
                    *ngIf="((submitted || confirmNewsecretkey.touched || confirmNewsecretkey.dirty) && confirmNewsecretkey.valid && (passwordData.confirmNewsecretkey != passwordData.newsecretkey))">
                    {{'PASSWORD.ERRORMESSAGE.confirmPassword'| translate}}
                </div>
            </div>
        </div>

        <div class="form-group text-center">
            <button type="submit" class="btn btn-primary">{{'LOGIN.Submit'|translate}}</button>
        </div>
    </form>
</div>